<template>
  <div>
    <van-cell-group title="主要信息">

      <!--      <van-cell title="日期" :value="date.val" @click="date.show = true" is-link/>-->
      <!--      <van-calendar v-model="date.show" @confirm="dateSel" :allow-same-day="true" :min-date="date.minDate"/>-->
      <van-cell title="日期" v-model="info.CREATED_DTStr" />
      <van-cell title="功能室" v-model="info.Func_Room_Name" />
      <van-cell title="设施" v-model="info.Facillities" />
      <van-cell title="教玩具" v-model="info.Room_Tools" />
    </van-cell-group>
    <van-cell-group title="附加信息">
      <van-cell title="类型" v-model="info.Activity_Type" />
      <van-cell title="活动内容" v-model="info.Activity_Content" />
      <div v-if="info && info.LST_Activity_Pics_URL.length > 0" class="imgbox">
        <div class="title">活动照片</div>
        <van-image v-for="item in info.LST_Activity_Pics_URL" :key="item.Key" width="100" height="100"
          style="margin: 0 5px 5px" :src="item.Value" />
      </div>
      <van-cell v-else title="活动照片" value="无" />
    </van-cell-group>
    <van-cell-group title="整理情况">
      <van-cell title="环境" v-model="model.Environment_State" @click="environmentState.show = true" is-link />
      <van-popup v-model="environmentState.show" round position="bottom">
        <van-picker :columns="environmentState.dat" @confirm="confirmEnvironmentState" show-toolbar
          @cancel="environmentState.show = false" />
      </van-popup>

      <van-cell title="设施" v-model="model.Facility_State" @click="facilityState.show = true" is-link />
      <van-popup v-model="facilityState.show" round position="bottom">
        <van-picker :columns="facilityState.dat" @confirm="confirmFacilityState" show-toolbar
          @cancel="facilityState.show = false" />
      </van-popup>

      <van-cell title="教玩具" v-model="model.Tool_State" @click="toolState.show = true" is-link />
      <van-popup v-model="toolState.show" round position="bottom">
        <van-picker :columns="toolState.dat" @confirm="confirmToolState" show-toolbar
          @cancel="toolState.show = false" />
      </van-popup>
      <van-cell title="活动老师" v-model="info.CreateUserName" />
      <van-field type="textarea" label="备注" v-model="model.Remark" placeholder="选择破损或损坏时备注说明情况" input-align="right" />
      <div class="imgbox">
        <div class="title">整理照片</div>
        <van-uploader :after-read="afterRead" multiple v-model="picList" @delete="deleteImg" max-count="6"
          style="margin: 10px 0px 0px 10px" />
      </div>
    </van-cell-group>
    <div style="margin: 15px">
      <van-button type="primary" size="large" icon="success" block @click="submit" :loading="submitting"
        v-if="!info.IsSure" loading-text="正在提交。。。">确认提交
      </van-button>
      <br />
      <van-button plain type="default" size="large" icon="cross" block @click="$router.go(-1)">取消返回</van-button>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import moment from "moment/moment";
export default {
  name: "confirm",
  data() {
    return {
      model: {},
      info: '',
      date: {
        show: false,
        val: moment(new Date()).format('YYYY-MM-DD')
      },
      environmentState: {
        show: false,
        dat: ['好', '差'],
      },
      facilityState: {
        show: false,
        dat: ['完好', '破损'],
      },
      toolState: {
        show: false,
        dat: ['完整', '损耗'],
      },
      submitting: false,
      picList: [],
      Organize_Pics: [],
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      let id = this.$route.query.id
      let self = this;
      this.whale.remote.getResult({
        url: "/api/Mobile/FUNC/FuncRoomUse/Get?id=" + id,
        // data:{id},
        completed(its) {
          console.log(its)
          self.info = its.DATA
          self.model = its.DATA
        }
      })
    },
    dateSel(d) {
      this.date.val = moment(d).format('YYYY-MM-DD')
      this.date.show = false
    },
    confirmEnvironmentState(val) {
      this.environmentState.show = false
      this.model.Environment_State = val
    },
    confirmFacilityState(val) {
      this.facilityState.show = false
      this.model.Facility_State = val
    },
    confirmToolState(val) {
      this.toolState.show = false
      this.model.Tool_State = val
    },
    afterRead(files) {
      let self = this
      if (!Array.isArray(files)) {
        files = [files]
      }
      files.forEach(item => {
        let b64Arr = item.content.split(',')
        this.whale.remote.getResult({
          url: "/api/Mobile/FUNC/FuncRoomUse/UpUseOrManPicture",
          data: { b64: b64Arr[1] },
          completed(its) {
            self.Organize_Pics.push(its.DATA)
          },
        })
      })
    },
    deleteImg(files, { index }) {
      this.Organize_Pics.splice(index, 1)
    },
    submit() {
      if (!this.model.Environment_State || !this.model.Facility_State || !this.model.Tool_State) {
        Toast.fail("请选择整理情况")
        return
      }

      let self = this;
      self.model.Organize_Pics = self.Organize_Pics.join(',')
      self.submitting = true;
      this.whale.remote.getResult({
        // url: "/api/Mobile/FUNC/FuncRoomUse/CreateNew",
        url: '/api/Mobile/FUNC/FuncRoomUse/ManUserUpdate',
        data: self.model,
        finally() {
          self.submitting = false
        },
        completed() {
          self.$dialog.alert({
            message: "记录已成功提交！"
          }).then(() => {
            // var firstSession = sessionStorage.getItem("ygjSession");
            // if (firstSession) {
            // 判断是否在微信环境
            const isWeixin = /MicroMessenger/i.test(navigator.userAgent);
            // 判断是否在微信小程序环境
            const isMiniProgram = /miniprogram/i.test(
              navigator.userAgent.toLowerCase()
            );
            if (isMiniProgram) {
              self.$router.go(-1);
            } else if (isWeixin) {
              // 如果在小程序内打开文件
                self.onCancel();
            } else {
              self.$router.go(-1);
            }
            // }
          })
        }
      })
    },
    onCancel() {
      setTimeout(function () {
        document.addEventListener(
          "WeixinJSBridgeReady",
          function () {
            this.WeixinJSBridge.call("closeWindow"); //安卓手机关闭代码
          },
          false
        );
        this.WeixinJSBridge.call("closeWindow"); //苹果手机关闭代码
      }, 300);
    }
  }
}
</script>

<style scoped>
.imgbox {
  padding: 10px;
}

.imgbox .title {
  padding: 0 6px;
  font-size: 14px;
  color: #646566;
  margin-bottom: 10px;
}
</style>
